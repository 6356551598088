import 'react-app-polyfill/ie11'
import {Config} from './config/config'
import 'index.css'

/*
 * Инициализируем настройки и запускаем приложение
 * */
Config.init().then(() => {
  import('./App')
})
